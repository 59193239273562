<template>
  <div class="columns is-gapless">
    <div class="column is-flexible">
      <div class="login box">
        <div class="has-text-left ml-3">
          <router-link to="/">
            <img width="160" src="@/../public/logo.png" alt="VO2Fast"/>
          </router-link>
        </div>
        <h2 class="title is-2 has-text-left mt-6">{{ $t('account.to_login') }}</h2>
        <form @submit.prevent="login()">
          <b-input size="is-large" v-model="email_username"  :placeholder="$t('account.email_or_username')+'*'"/><br/>
          <b-input size="is-large" v-model="password"  name="password" :placeholder="$t('account.password')+'*'" type="password" password-reveal/><br/>
          <p class="has-text-right"><router-link class="has-text-secondary-light has-text-weight-semibold" to="/forgot">{{ $t('account.forgot_password') }}</router-link></p>
          <b-button size="is-large" class="is-primary has-text-weight-bold mt-6" native-type="submit">{{ $t('account.to_login') }}</b-button>
          <p v-if="error" class="has-text-danger">{{ $t(errormsg) }}</p>
          <p class="mt-3">{{ $t('account.not_signed_up') }} <router-link class="has-text-weight-semibold" to="/signup">{{ $t('account.signup') }}</router-link></p>
        </form>
      </div>
    </div>
    <img class="column is-half fit-cover" v-if="$root.isDesktop()" src="~@/assets/loginImage.jpg"/>
  </div>
</template>

<script>
export default {
  name: 'login',
  data(){
    return {
      error: false,
      errormsg: "",
      email_username: "",
      password: ""
    };
  },
  methods: {
    checkData(){
      this.errormsg = 'errors.account.';
      if(this.email == ""){
        this.errormsg += 'enter_email_or_username';
        this.error = true;
        return false;
      }
      if(this.password == ""){
        this.errormsg += 'enter_password';
        this.error = true;
        return false;
      }
      this.errormsg = '';
      return true;
    },

    trackLogin(){
      try{
        this.$gtag.event('login');
        window.fbq('track', 'ViewContent');
      }catch(err){
        console.error(err);
      }
    },

    async discourseLogin(){
      const sso = this.$route.query.sso;
      const sig = this.$route.query.sig;
      if(sso && sig){
        try{
          const res = await this.post('/discourse_login', { payload: sso, sig: sig });
          window.location.replace(res);
        }catch(err){
          console.error(err);
        }
      }
    },

    async login() {
      this.error = false;
      if(this.checkData()){
        var data = { email_username: this.email_username, password: this.password };
        try{
          try{
            await this.$recaptchaLoaded(); //Recaptcha on login
            const token = await this.$recaptcha('login');
            await this.post('/recaptcha', { username: this.email_username, token: token, action: 'login' });
          }catch(err){ console.error(err); }

          await this.$store.dispatch('login', data);
          this.trackLogin();
          this.discourseLogin();
        }catch(err){
          this.errormsg = err;
          this.error = true;
        }
      }
    },

  },
  beforeMount(){  //If user is logged in, redirect ASAP to the dashboard
    if(this.$store.getters.isLoggedIn){
      this.discourseLogin(); //If discourse redirect login, login to discourse and redirect there instead
      this.$router.push('/dashboard');
    }
  },
}
</script>

<style lang="scss" scoped>

.login{
  padding: 20px;
  @include from($desktop){ padding: 100px; }
  min-height: 100vh;
}

.error {
  color: red;
}
</style>
